import React, { Component } from 'react';
import Modal from '../../../web-common/components/Modal';
import SessionStorage from '../../../web-common/client/SessionStorage';
import UnityInvoiceResult from '../../../web-common/models/orders/UnityInvoiceResult';
import Urls from '../../../web-common/config/Urls';
import AccountService from '../../../services/AccountService';
import '../../../web-common/styles/theme-button.scss';
import './styles.scss';
import PublicApiError from '../../../web-common/models/errors/PublicApiError';
import ExternalLicense from '../../../web-common/models/accounts/ExternalLicense';
import TextSubmissionInput from '../../../web-common/components/TextSubmissionInput';
import { Link } from 'react-router-dom';

interface AddProductModalProps {
  onCloseRequested: () => void;
  onUserLicensesChanged: () => void;
  isAdmin: boolean;
}

export default class AddProductModal extends Component<AddProductModalProps> {

  state = {
    assetStoreInvoiceNumber: '',
    submissionInProgress: false,
    invoiceResult: null as UnityInvoiceResult | null,
    error: ''
  };

  render() {

    return (
      <Modal className="add-product-modal" onCloseRequested={this.state.submissionInProgress ? undefined : this.props.onCloseRequested}>
        {this._renderContent()}
      </Modal>
    )
  }

  private _handleInvoiceNumberChange = (assetStoreInvoiceNumber) => {

    this.setState({
      assetStoreInvoiceNumber,
      error: ''
    });
  }

  private _handleSubmitButtonClicked = async () => {

    this.setState({ submissionInProgress: true });
    try {
      const invoiceResult = await AccountService.redeemUnityStorePurchase(this.state.assetStoreInvoiceNumber);
      this.setState({
        submissionInProgress: false,
        invoiceResult
      });

      const licensesForCurrentUserChanged = invoiceResult.licenses.find(l => l.userId === SessionStorage.getSession()!.userId);
      if (licensesForCurrentUserChanged) {
        this.props.onUserLicensesChanged();
      }
    } catch (error) {
      const errorMessage = (error instanceof PublicApiError && error.userMessage) || `An unexpected error occurred: ${error}`;
      this.setState({
        submissionInProgress: false,
        error: errorMessage
      });
    }
  };

  private _renderContent() {

    if (!this.props.isAdmin) {
      return (
        <div className="non-admin">
          <h2>Please contact your account admin</h2>
          <p>
            Sorry, but only account administrators can modify user licenses. Please contact your
            account admin to request a license.
          </p>
        </div>
      );
    }
    if (this.state.invoiceResult) {
      return this._renderInvoiceResult(this.state.invoiceResult);
    }
    return this._renderDefaultContent();
  }

  private _renderDefaultContent() {

    const { assetStoreInvoiceNumber } = this.state;
    const invoiceNumberIsValid = assetStoreInvoiceNumber.indexOf('IN') === 0 && assetStoreInvoiceNumber.length === 14;

    return (
      <div>
        <a className="store-link" href={Urls.Store}>Visit the Vuplex store</a>
        <div className="divider">
          <span>or</span>
        </div>
        <div className="asset-store-entry">
          Enter an Asset Store invoice number:
          <TextSubmissionInput
            value={this.state.assetStoreInvoiceNumber}
            onChange={this._handleInvoiceNumberChange}
            onSubmit={this._handleSubmitButtonClicked}
            submissionInProgress={this.state.submissionInProgress}
            isValid={invoiceNumberIsValid}
            buttonText="Redeem"
            error={this.state.error}
            placeholder="INXXXXXXXXXXXX"/>
        </div>
      </div>
    )
  }

  private _renderAddedLicenses(licenses: ExternalLicense[]) {

    if (licenses.length === 1) {
      const license = licenses[0];
      return (
        <div className="licenses">
          {license.productName} {this._renderLicenseAssignment(license)}
        </div>
      );
    }
    return (
      <div className="licenses-container">
        <ul className="licenses">
          {licenses.map(this._renderLicense)}
        </ul>
      </div>
    )
  }

  private _renderLicense = (license: ExternalLicense) => {

    return (
      <li>
        {license.productName} {this._renderLicenseAssignment(license)}
      </li>
    );
  };

  private _renderLicenseAssignment(license: ExternalLicense) {

    if (license.userId === SessionStorage.getSession()!.userId) {
      return <span>(assigned to you)</span>
    }
    return <span>(unassigned)</span>
  }

  private _renderInvoiceResult(invoiceResult: UnityInvoiceResult) {

    const multiple = invoiceResult.licenses.length > 1;
    const message =  multiple ? 'The following licenses were added to your account:' : 'The following license was added to your account:';

    return (
      <div className="invoice-result">
        <h2>Thanks!</h2>
        <p>
          {message}
        </p>
        {this._renderAddedLicenses(invoiceResult.licenses)}
        <p className="management-link">
          You can manage users and licenses <Link to="/admin/users">here</Link>.
        </p>
      </div>
    );
  }
}
