import React, { Component } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import DefaultHead from '../DefaultHead';
import AccountService from '../../services/AccountService';
import LoginRedirectError from '../../web-common/client/LoginRedirectError';
import parseQueryString from '../../web-common/utils/parseQueryString';
import addImage from '../../web-common/assets/add.svg';
import documentImage from '../../shared-assets/document.svg';
import UserAccountOverview from '../../web-common/models/accounts/UserAccountOverview';
import PublicApiError from '../../web-common/models/errors/PublicApiError';
import ProductDownload from './ProductDownload';
import PageHeader from '../PageHeader';
import PageError from '../../web-common/components/PageError';
import PageSpinner from '../PageSpinner';
import accountImage from './assets/account-blue.svg';
import SessionStorage from '../../web-common/client/SessionStorage';
import AddProductModal from './AddProductModal';
import '../../web-common/styles/link-button.scss';
import './styles.scss';

export default class OverviewPage extends Component<RouteComponentProps> {

  state = {
    error: '',
    accountOverview: null as UserAccountOverview|null,
    showAddProductModal: false,
    isAdmin: false
  }

  async componentDidMount() {

    const sessionInfo = SessionStorage.getSession();
    this.setState({ isAdmin: !!(sessionInfo && sessionInfo.isAdmin) })
    this._loadAccountOverview();
    const { addProduct } = parseQueryString(this.props.location.search)
    if (addProduct) {
      this.setState({ showAddProductModal: true });
      this.props.history.replace(this.props.location.pathname);
    }
  }

  render() {
    return (
      <div className="overview-page">
        <DefaultHead/>
        {this._renderContent()}
        {this.state.showAddProductModal && (
          <AddProductModal
            onCloseRequested={this._handleCloseModalRequested}
            onUserLicensesChanged={this._loadAccountOverview}
            isAdmin={this.state.isAdmin}/>
        )}
      </div>
    );
  }

  private _handleAddProductButtonClick = () => this.setState({ showAddProductModal: true });

  private _handleCloseModalRequested = () => this.setState({ showAddProductModal: false });

  private _loadAccountOverview = async () => {

    this.setState({ accountOverview: null });
    try {
      const accountOverview = await AccountService.getUserAccountOverview();
      this.setState({ accountOverview });
    } catch (error) {
      if (error instanceof LoginRedirectError) {
        // Ignore this error
        return;
      }
      const errorMessage = (error instanceof PublicApiError && error.userMessage) || `An unexpected error occurred while loading account data. ${error}`;
      this.setState({ error: errorMessage });
    }
  }

  private _renderContent() {

    if (this.state.error) {
      return <PageError error={this.state.error}/>;
    }
    if (!this.state.accountOverview) {
      return <PageSpinner/>;
    }

    return (
      <div>
        <section>
          <PageHeader>Product downloads</PageHeader>
          {this._renderProductDownloads()}
        </section>
        <div>
          <button className="link-button bottom-link" onClick={this._handleAddProductButtonClick}>
            <img src={addImage} alt="add symbol" />
            Add products
          </button>
          {/* Only show the users management link if the account has products. */}
          {this.state.isAdmin && this.state.accountOverview!.productSummaries.length > 0 && (
            <Link className="bottom-link" to="/admin/users">
              <img src={accountImage} alt="account icon" />
              Manage users and licenses
            </Link>
          )}
          {this.state.accountOverview!.hasInvoices && (
            <Link className="bottom-link" to="/admin/invoices">
              <div className="invoice-icon">
                <img src={documentImage} alt="account icon" />
              </div>
              View invoices
            </Link>
          )}
        </div>
      </div>
    )
  }

  private _renderProductDownloads() {

    const { accountOverview } = this.state;

    if (!(accountOverview && accountOverview.productSummaries.length)) {
      return (
        <div className="downloads-empty">
          <p>No products added yet. {this.state.isAdmin && <button className="link-button" onClick={this._handleAddProductButtonClick}>Click here to add one.</button>}</p>
        </div>
      );
    }

    return (
      <div className="downloads">
        {accountOverview.productSummaries.map(p => <ProductDownload productSummary={p} key={p.product.id}/>)}
      </div>
    );
  }
}
