import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import PublicApiError from '../../web-common/models/errors/PublicApiError';
import UserProductSummary from '../../web-common/models/accounts/UserProductSummary';
import LoginRedirectError from '../../web-common/client/LoginRedirectError';
import AccountService from '../../services/AccountService';
import PageError from '../../web-common/components/PageError';
import PageHeader from '../PageHeader';
import PageSpinner from '../PageSpinner';
import ProductRelease from './ProductRelease';
import './styles.scss';

export default class UserProductPage extends Component<RouteComponentProps<{ productId: string }>> {

  state = {
    error: '',
    productSummary: null as UserProductSummary|null
  };

  async componentDidMount() {
    try {
      const { productId } = this.props.match.params;
      const productSummary = await AccountService.getUserProduct(productId);
      this.setState({ productSummary });
    } catch (error) {
      if (error instanceof LoginRedirectError) {
        // Ignore this error
        return;
      }
      const errorMessage = (error instanceof PublicApiError && error.userMessage) || `An unexpected error occurred while loading account data. ${error}`;
      this.setState({ error: errorMessage });
    }

  }

  render() {

    if (this.state.error) {
      return <PageError error={this.state.error}/>;
    }
    if (!this.state.productSummary) {
      return <PageSpinner/>;
    }

    const { product } = this.state.productSummary;

    return (
      <div className="product-page">
        <Helmet>
          <title>{product.name} | Vuplex</title>
          <meta name="description" content={product.name} />
        </Helmet>
        <PageHeader back="/overview" label="Releases">{product.name}</PageHeader>
        {this._renderReleases()}
      </div>
    );
  }

  private _renderReleases() {

    const { releases } = this.state.productSummary!;

    if (!releases.length) {
      return (
        <div className="no-releases">
          No releases yet
        </div>
      );
    }
    return (
      <div>
        {releases.map(r => <ProductRelease release={r} key={r.id}/>)}
      </div>
    );
  }
}
