import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import PublicApiError from '../../web-common/models/errors/PublicApiError';
import AccountService from '../../services/AccountService';
import LoginRedirectError from '../../web-common/client/LoginRedirectError';
import PageError from '../../web-common/components/PageError';
import PageHeader from '../PageHeader';
import PageSpinner from '../PageSpinner';
import ExternalUser from '../../web-common/models/accounts/ExternalUser';
import addImage from '../../web-common/assets/add.svg';
import AddUserModal from './AddUserModal';
import DeleteUserModal from './DeleteUserModal';
import minusImage from '../../shared-assets/minus.svg';
import '../../web-common/styles/link-button.scss';
import '../../web-common/styles/button-reset.scss';
import '../../shared-styles/card.scss'
import './styles.scss';
import ExternalLicense from '../../web-common/models/accounts/ExternalLicense';

export default class UsersPage extends Component {

  state = {
    error: '',
    users: null as ExternalUser[] | null,
    licenses: null as ExternalLicense[] | null,
    showAddUserModal: false,
    userPendingDeletion: null as ExternalUser | null
  }

  async componentDidMount() {

    await this._loadUsers();
  }

  render() {

    const { licenses, users } = this.state;

    return (
      <div className="users-page">
        <Helmet>
          <title>Users | Vuplex</title>
          <meta name="description" content="Manage your organization's users." />
        </Helmet>
        {this._renderContent()}
        {this.state.showAddUserModal && (
          <AddUserModal
            numberOfLicenses={licenses ? licenses.length : 0}
            numberOfUsers={users ? users.length : 0}
            onCloseRequested={this._handleAddUserModalCloseRequested}
            onUsersChanged={this._loadUsers}/>
        )}
        {this.state.userPendingDeletion && (
          <DeleteUserModal
            email={this.state.userPendingDeletion.email}
            onCloseRequested={this._handleDeleteUserModalCloseRequested}
            onDeletionConfirmed={this._handleDeletionConfirmed}/>
        )}
      </div>
    )
  }

  private _handleAddUserButtonClick = () => {

    this.setState({ showAddUserModal: true });
  };

  private _handleDeleteButtonClicked = (event: React.MouseEvent<HTMLButtonElement>) => {

    const userId = (event.currentTarget as HTMLButtonElement).dataset.user;
    const user = this.state.users!.find(u => u.id === userId);
    this.setState({ userPendingDeletion: user });
    // Prevent the click from bubbling up to the anchor.
    event.preventDefault();
    event.stopPropagation();
  };

  private _handleDeletionConfirmed = async () => {

    const user = this.state.userPendingDeletion;
    this.setState({
      users: null,
      licenses: null,
      userPendingDeletion: null
    });
    try {
      await AccountService.deleteUser(user!.id);
      await this._loadUsers();
    } catch (error) {
      const errorMessage = (error instanceof PublicApiError && error.userMessage) || `An unexpected error occurred while deleting the user. ${error}`;
      this.setState({ error: errorMessage });
    }
  };

  private _handleAddUserModalCloseRequested = () => {

    this.setState({ showAddUserModal: false });
  }

  private _handleDeleteUserModalCloseRequested = () => {

    this.setState({ userPendingDeletion: null });
  }

  private _loadUsers = async () => {

    this.setState({ users: null, licenses: null });
    try {
      const [users, licenses] = await Promise.all([
        AccountService.getUsers(),
        AccountService.getLicenses()
      ]);
      this.setState({ users, licenses });
    } catch (error) {
      if (error instanceof LoginRedirectError) {
        // Ignore this error
        return;
      }
      const errorMessage = (error instanceof PublicApiError && error.userMessage) || `An unexpected error occurred while loading account data. ${error}`;
      this.setState({ error: errorMessage });
    }
  }

  private _renderContent() {

    if (this.state.error) {
      return <PageError error={this.state.error} />;
    }
    if (!(this.state.users && this.state.licenses)) {
      return <PageSpinner />;
    }

    return (
      <div>
        <section>
          <PageHeader back="/overview">Users</PageHeader>
          {this.state.users.map(this._renderUser)}
          <button className="link-button add-user-button" onClick={this._handleAddUserButtonClick}>
            <img src={addImage} alt="add symbol" />
            Add user
          </button>
        </section>
      </div>
    )
  }

  private _renderLicenses(user: ExternalUser) {

    const licenses = this.state.licenses!.filter(l => l.userId === user.id)
                                         .sort((a, b) => a.productName > b.productName ? 1 : -1);

    if (licenses.length) {
      return (
        <div>
          Assigned licenses:
          <ul className="licenses">
            {licenses.map(l => <li key={l.id}>{l.productName}</li>)}
          </ul>
        </div>
      );
    }
    return (
      <div className="no-licenses">
        No licenses assigned
      </div>
    );
  }

  private _renderUser = (user: ExternalUser) => (

    <Link to={`/admin/users/${user.id}`} className="user card" key={user.id}>
      <div>
        <div className="user-top">
          <h2>{user.email}</h2>
          {user.isAdmin ? <span className="admin-badge">Admin</span> : (
            <button
              data-user={user.id}
              onClick={this._handleDeleteButtonClicked}
              className="button-reset remove-button">
              <img src={minusImage} alt="minus symbol" />
              <span>Delete</span>
            </button>
          )}
        </div>
        {this._renderLicenses(user)}
      </div>
    </Link>
  )
}
