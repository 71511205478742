import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom'
import { Helmet } from 'react-helmet';
import PublicApiError from '../../web-common/models/errors/PublicApiError';
import editImage from './edit.svg';
import documentImage from '../../shared-assets/document.svg';
import LoginRedirectError from '../../web-common/client/LoginRedirectError';
import AccountService from '../../services/AccountService';
import PageError from '../../web-common/components/PageError';
import PageHeader from '../PageHeader';
import PageSpinner from '../PageSpinner';
import CompanyInfoModal from './CompanyInfoModal';
import '../../shared-styles/empty-container.scss';
import '../../web-common/styles/link-button.scss';
import './styles.scss';

export default class InvoicePage extends Component<RouteComponentProps<{ orderId: string }>> {

  state = {
    error: '',
    invoiceHtml: '',
    companyInfo: '',
    showCompanyInfoModal: false,
  }

  async componentDidMount() {

    const { orderId} = this.props.match.params;
    try {
      const invoiceHtml = await AccountService.getInvoiceHtml(orderId)
      this.setState({ invoiceHtml });
    } catch (error) {
      if (error instanceof LoginRedirectError) {
        // Ignore this error
        return;
      }
      const errorMessage = (error instanceof PublicApiError && error.userMessage) || `An unexpected error occurred while loading account data. ${error}`;
      this.setState({ error: errorMessage });
    }
  }

  render() {

    return (
      <div className="invoice-page">
        <Helmet>
          <title>Invoice | Vuplex</title>
          <meta name="description" content="Vuplex invoice." />
        </Helmet>
        {this._renderContent()}
      </div>
    )
  }

  private _iframe?: HTMLIFrameElement;
  private get _titleForEditingCompanyInfo() {
    return this.state.companyInfo ? 'Edit company info' : 'Add company info';
  }

  private _handleCompanyInfoButtonClick = () => this.setState({ showCompanyInfoModal: true });

  private _handleModalUpdatedCompanyInfo = (companyInfo) => {

    this.setState({ companyInfo, showCompanyInfoModal: false });
    this._iframe!.contentDocument!.getElementById('customer-info')!.innerText = companyInfo;
  }

  private _handlePrintButtonClick = () => this._iframe!.contentWindow!.print();

  private _handleModalCloseRequested = () => this.setState({ showCompanyInfoModal: false });

  private _renderCompanyInfoModal() {

    if (this.state.showCompanyInfoModal) {
      return (
        <CompanyInfoModal
          title={this._titleForEditingCompanyInfo}
          companyInfo={this.state.companyInfo}
          onApply={this._handleModalUpdatedCompanyInfo}
          onCloseRequested={this._handleModalCloseRequested}/>
      );
    }
  }

  private _renderContent() {

    if (this.state.error) {
      return <PageError error={this.state.error}/>;
    }
    if (!this.state.invoiceHtml) {
      return <PageSpinner/>;
    }
    return (
      <div>
        <section>
          <PageHeader back="/admin/invoices">Invoice</PageHeader>
          <div>
            <button className="link-button top-button" onClick={this._handlePrintButtonClick}>
              <img src={documentImage} alt="add symbol" />
              Save as PDF
            </button>
            <button className="link-button top-button" onClick={this._handleCompanyInfoButtonClick}>
              <img src={editImage} alt="add symbol" />
              {this._titleForEditingCompanyInfo}
            </button>
            <iframe ref={this._setIframeRef} srcDoc={this.state.invoiceHtml} />
          </div>
        </section>
        {this._renderCompanyInfoModal()}
      </div>
    );
  }

  private _setIframeRef = iframe => this._iframe = iframe;
}
