import VuplexApi from '../web-common/client/VuplexApi';
import SessionStorage from '../web-common/client/SessionStorage';
import getApiErrorForResponse from '../web-common/utils/getApiErrorForResponse';
import ExternalUser from '../web-common/models/accounts/ExternalUser';
import ExternalLicense from '../web-common/models/accounts/ExternalLicense';
import UnityInvoiceResult from '../web-common/models/orders/UnityInvoiceResult';
import Invoice from '../web-common/models/accounts/Invoice';

export default class AccountService {

  static async createUser(email: string) {

    const { accountId } = SessionStorage.getSessionOrNavigateToLogin();
    const response = await VuplexApi.authenticatedFetch(
      `/accounts/${accountId}/admin/users`,
      { method: 'POST', body: JSON.stringify({ email }) }
    );
    if (!response.ok) {
      throw await getApiErrorForResponse(response);
    }
  }

  static async deleteUser(userId: string) {

    const { accountId } = SessionStorage.getSessionOrNavigateToLogin();
    const response = await VuplexApi.authenticatedFetch(
      `/accounts/${accountId}/admin/users/${userId}`,
      { method: 'DELETE' }
    );
    if (!response.ok) {
      throw await getApiErrorForResponse(response);
    }
  }

  static async getInvoices(): Promise<Invoice[]> {

    const { accountId } = SessionStorage.getSessionOrNavigateToLogin();
    const result = await VuplexApi.authenticatedGetJson(`/accounts/${accountId}/admin/invoices`);
    return result;
  }

  static async getInvoiceHtml(orderId: string): Promise<string> {

    const { accountId } = SessionStorage.getSessionOrNavigateToLogin();
    const result = await VuplexApi.authenticatedGetText(`/accounts/${accountId}/admin/invoices/${orderId}/html`);
    return result;
  }

  static async getLicenses(): Promise<ExternalLicense[]> {

    const { accountId } = SessionStorage.getSessionOrNavigateToLogin();
    const result = await VuplexApi.authenticatedGetJson(`/accounts/${accountId}/admin/licenses`);
    return result;
  }

  static async getUsers(): Promise<ExternalUser[]> {

    const { accountId } = SessionStorage.getSessionOrNavigateToLogin();
    const result = await VuplexApi.authenticatedGetJson(`/accounts/${accountId}/admin/users`);
    return result;
  }

  static async getUserAccountOverview() {

    const { userId, accountId } = SessionStorage.getSessionOrNavigateToLogin();
    const result = await VuplexApi.authenticatedGetJson(`/accounts/${accountId}/users/${userId}/overview`);
    return result;
  }

  static async getUserProduct(productId: string) {

    const { userId, accountId } = SessionStorage.getSessionOrNavigateToLogin();
    const result = await VuplexApi.authenticatedGetJson(`/accounts/${accountId}/users/${userId}/products/${productId}`);
    return result;
  }

  static async redeemUnityStorePurchase(invoiceNumber: string): Promise<UnityInvoiceResult> {

    const { accountId } = SessionStorage.getSessionOrNavigateToLogin();
    const response = await VuplexApi.authenticatedFetch(
      `/accounts/${accountId}/admin/orders/unity-invoices`,
      { method: 'POST', body: JSON.stringify({ invoiceNumber }) }
    );
    if (response.ok) {
      const result = await response.json();
      return result as UnityInvoiceResult;
    }
    throw await getApiErrorForResponse(response);
  }

  static async triggerAuthenticatedDownload(downloadUrl: string) {

    const { url } = await VuplexApi.authenticatedGetJson(downloadUrl);
    window.open(url);
  }

  static async updateLicense(licenseId: string, userId: string) {

    const { accountId } = SessionStorage.getSessionOrNavigateToLogin();
    const response = await VuplexApi.authenticatedFetch(
      `/accounts/${accountId}/admin/licenses/${licenseId}`,
      // Note: I found that 'PATCH' actually does need to be upper case for API Gateway
      { method: 'PATCH', body: JSON.stringify({ userId}) }
    );
    if (!response.ok) {
      throw await getApiErrorForResponse(response);
    }
  }
}
