import React, { Component } from 'react';
import Modal from '../../../web-common/components/Modal';
import '../../../web-common/styles/theme-button.scss';
import './styles.scss';

interface DeleteUserModalProps {
  onCloseRequested: () => void;
  onDeletionConfirmed: () => void;
  email: string;
}

export default class DeleteUserModal extends Component<DeleteUserModalProps> {

  render() {
    return (
      <Modal
        className="delete-user-modal"
        onCloseRequested={this.props.onCloseRequested}>
          <div>
            <h2>Are you sure you want to delete this user?</h2>
            <p>
              If you delete {this.props.email}, they will no longer be able
              to access your account, and all of the licenses assigned to them
              will be set to unassigned.
            </p>
            <div className="buttons">
              <button
                className="theme-button cancel"
                onClick={this.props.onCloseRequested}>
                  Cancel
              </button>
              <button
                className="theme-button delete"
                onClick={this.props.onDeletionConfirmed}>
                  Delete
              </button>
            </div>
          </div>
      </Modal>
    );
  }
}
