import React, { Component } from 'react';
import Modal from '../../../web-common/components/Modal';
import '../../../web-common/styles/theme-button.scss';
import './styles.scss';

interface CompanyInfoModalProps {
  title: string;
  companyInfo: string;
  onCloseRequested: () => void;
  onApply: (companyInfo: string) => void;
}

export default class CompanyInfoModal extends Component<CompanyInfoModalProps> {

  state = {
    companyInfo: this.props.companyInfo
  }

  render() {
    return (
      <Modal className="company-info-modal" onCloseRequested={this.props.onCloseRequested}>
        <h2>{this.props.title}</h2>
        <p>
          Any text entered here is added to the invoice's "Billed to" section.
        </p>
        <textarea placeholder="Company name, address, tax ID, etc." value={this.state.companyInfo} onChange={this._handleChange}></textarea>
        <button className="theme-button" onClick={this._handleApplyButtonClick}>Apply to invoice</button>
      </Modal>
    );
  }

  private _handleApplyButtonClick = () => this.props.onApply(this.state.companyInfo);

  private _handleChange = (event) => this.setState({ companyInfo: event.target.value });
}
