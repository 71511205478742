import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import arrowImage from './assets/arrow.svg';
import './styles.scss';

interface PageHeaderProps {
  back?: string;
  label?: string;
}

export default class PageHeader extends Component<PageHeaderProps> {

  render() {
    return (
      <h1 className="page-header">
        {this._renderBackLink()}
        <span>{this.props.children}</span>
        {this.props.label && <span className="header-label">{this.props.label}</span>}
      </h1>
    );
  }

  private _renderBackLink() {

    if (this.props.back) {
      return (
        <Link to={this.props.back}>
          <img alt="back arrow" src={arrowImage}/>
        </Link>
      )
    }
  }
}
