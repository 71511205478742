import React, { Component } from 'react';
import './styles.scss';

interface HelpTooltipProps {
  className?: string;
  size?: string;
  backgroundColor?: string;
}

export default class HelpTooltip extends Component<HelpTooltipProps> {

  render() {
    return (
      <div className={`help-tooltip ${this.props.className || ''}`}>
        <svg width={this.props.size || '20px'} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496.158 496.158">
          <path d="M496.158 248.085C496.158 111.063 385.089.003 248.083.003 111.07.003 0 111.063 0 248.085c0 137.001 111.07 248.07 248.083 248.07 137.006 0 248.075-111.069 248.075-248.07z" fill={this.props.backgroundColor || '#12bae9'} />
          <path d="M138.216 173.592c0-13.915 4.467-28.015 13.403-42.297 8.933-14.282 21.973-26.11 39.111-35.486 17.139-9.373 37.134-14.062 59.985-14.062 21.238 0 39.99 3.921 56.25 11.755 16.26 7.838 28.818 18.495 37.683 31.97 8.861 13.479 13.293 28.125 13.293 43.945 0 12.452-2.527 23.367-7.581 32.739-5.054 9.376-11.062 17.469-18.018 24.279-6.959 6.812-19.446 18.275-37.463 34.388-4.981 4.542-8.975 8.535-11.975 11.976-3.004 3.443-5.239 6.592-6.702 9.447-1.466 2.857-2.603 5.713-3.406 8.57-.807 2.855-2.015 7.875-3.625 15.051-2.784 15.236-11.501 22.852-26.147 22.852-7.618 0-14.028-2.489-19.226-7.471-5.201-4.979-7.8-12.377-7.8-22.192 0-12.305 1.902-22.962 5.713-31.97 3.808-9.01 8.861-16.92 15.161-23.73 6.296-6.812 14.794-14.904 25.488-24.28 9.373-8.202 16.15-14.392 20.325-18.567a62.97 62.97 0 0 0 10.547-13.953c2.856-5.126 4.285-10.691 4.285-16.699 0-11.718-4.36-21.605-13.074-29.663-8.717-8.054-19.961-12.085-33.728-12.085-16.116 0-27.981 4.065-35.596 12.195-7.618 8.13-14.062 20.105-19.336 35.925-4.981 16.555-14.43 24.829-28.345 24.829-8.206 0-15.127-2.891-20.764-8.679-5.639-5.786-8.458-12.048-8.458-18.787zm107.226 240.82c-8.937 0-16.737-2.895-23.401-8.68-6.667-5.784-9.998-13.877-9.998-24.279 0-9.229 3.22-16.991 9.668-23.291 6.444-6.297 14.354-9.448 23.73-9.448 9.229 0 16.991 3.151 23.291 9.448 6.296 6.3 9.448 14.062 9.448 23.291 0 10.255-3.296 18.312-9.888 24.17-6.592 5.858-14.208 8.789-22.85 8.789z" fill="#fff" />
        </svg>
        {this._renderPopup()}
      </div>
    );
  }

  private _renderPopup() {

    return (
      <div className="popup-container">
        <div>
          {this.props.children}
          <div className="pointer"/>
        </div>
      </div>
    );
  }
}
