import React, { Component } from 'react';
import AccountService from '../../../services/AccountService';
import Modal from '../../../web-common/components/Modal';
import Urls from '../../../web-common/config/Urls';
import PublicApiError from '../../../web-common/models/errors/PublicApiError';
import isValidEmailAddress from '../../../web-common/utils/isValidEmailAddress';
import TextSubmissionInput from '../../../web-common/components/TextSubmissionInput';
import './styles.scss';

interface AddUserModalProps {
  onCloseRequested: () => void;
  numberOfUsers: number;
  numberOfLicenses: number;
  onUsersChanged: () => void;
}

enum SubmissionState {
  Unsubmitted,
  InProgress,
  Finished
}

export default class AddUserModal extends Component<AddUserModalProps> {

  state = {
    email: '',
    error: '',
    submissionState: SubmissionState.Unsubmitted
  };

  render() {
    return (
      <Modal className="add-user-modal" onCloseRequested={this.props.onCloseRequested}>
        {this._renderContent()}
      </Modal>
    )
  }

  private _renderContent() {

    const { submissionState } = this.state;
    if (submissionState == SubmissionState.Finished) {
      return (
        <div className="finished">
          <h2>New user added</h2>
          <p>
            We sent an invitation email to {this.state.email}.
          </p>
        </div>
      );
    }

    const { numberOfLicenses, numberOfUsers } = this.props;
    const maxNumberOfUsers = numberOfLicenses + 1;

    if (numberOfUsers >= maxNumberOfUsers) {
      return (
        <div className="max-users">
          Sorry, but an account with {numberOfLicenses} {numberOfLicenses === 1 ? 'license' : 'licenses'} can only have up to {maxNumberOfUsers} users. To add a new user, please either <a href={Urls.Store}>purchase another license</a> or delete an existing user.
        </div>
      )
    }
    return (
      <div>
        <h2>Add a user</h2>
        <p>
          Enter the email address for the user you want to add, and we'll send them an email to invite them to your account.
        </p>
        <TextSubmissionInput
          value={this.state.email}
          onChange={this._handleEmailChange}
          onSubmit={this._handleSubmission}
          submissionInProgress={this.state.submissionState == SubmissionState.InProgress}
          isValid={isValidEmailAddress(this.state.email)}
          buttonText="Invite"
          error={this.state.error}
          inputType="email"
          placeholder="Email address" />
      </div>
    );
  }

  private _handleEmailChange = (email: string) => this.setState({ email });

  private _handleSubmission = async () => {
    this.setState({ submissionState: SubmissionState.InProgress });
    try {
      await AccountService.createUser(this.state.email);
      this.setState({ submissionState: SubmissionState.Finished });
      this.props.onUsersChanged();
    } catch (error) {
      const errorMessage = (error instanceof PublicApiError && error.userMessage) || `An unexpected error occurred: ${error}`;
      this.setState({
        submissionState: SubmissionState.Unsubmitted,
        error: errorMessage
      });
    }
  };
}
