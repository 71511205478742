import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import HelpTooltip from '../../../web-common/components/HelpTooltip';
import Modal from '../../../web-common/components/Modal';
import ExternalLicense from '../../../web-common/models/accounts/ExternalLicense';
import '../../../web-common/styles/theme-button.scss';
import './styles.scss';

interface AddLicenseModalProps {
  userId: string;
  licenses: ExternalLicense[];
  onCloseRequested: () => void;
  onAddRequested: (licenseId: string) => void;
}

export default class AddLicenseModel extends Component<AddLicenseModalProps> {

  state = {
    selectedLicenseId: undefined as string | undefined,
    selectableLicenses: undefined as ExternalLicense[] | undefined
  }

  componentWillMount() {

    const selectableLicenses = this._getSelectableLicenses();
    const selectedLicense = selectableLicenses[0];
    this.setState({
      selectableLicenses,
      selectedLicenseId: selectedLicense && selectedLicense.id
    });
  }

  render() {
    return (
      <Modal className="add-license-modal" onCloseRequested={this.props.onCloseRequested}>
        {this._renderContent()}
      </Modal>
    );
  }

  private _getSelectableLicenses() {

    const productIdsAlreadyAssignedToUser = this.props.licenses.filter(l => l.userId === this.props.userId)
      .map(l => l.productId);

    const selectableLicenses = this.props.licenses.reduce((accumulator, license) => {
      if (license.userId !== 'unassigned') {
        return accumulator;
      }
      // For now, only show one license for each product ID. In the future, show
      // all available licenses so the user can choose the right one (i.e. based on expiration).
      const accumulatedProductIds = accumulator.map(l => l.productId);

      const includeLicenseInDropdown = !accumulatedProductIds.includes(license.productId) && !productIdsAlreadyAssignedToUser.includes(license.productId);

      if (includeLicenseInDropdown) {
        accumulator.push(license);
      }
      return accumulator
    }, [] as ExternalLicense[]);

    return selectableLicenses;
  }

  private _handleAddButtonClicked = () => this.props.onAddRequested(this.state.selectedLicenseId!);

  private _handleDropdownChange = (event) => this.setState({ selectedLicenseId: event.target.value });

  private _renderContent() {

    if (this.state.selectableLicenses!.length === 0) {
      return (
        <div>
          <h2>No unassigned licenses</h2>
          <p>
            Your account doesn't currently have any unassigned licenses. To add a license to this user, please
            either <Link to="/admin/users">unassign a license from another user</Link> first
            or <Link to="/?addProduct=true">add a new license</Link>.
          </p>
        </div>
      );
    }

    return (
      <div>
        <h2>Add a license</h2>
        <div className="dropdown-container">
          <select value={this.state.selectedLicenseId} onChange={this._handleDropdownChange}>
            {this.state.selectableLicenses!.map(this._renderLicenseOption)}
          </select>
          <HelpTooltip>
            Don't see the license you're looking for? You may need to <Link to="/admin/users">unassign one from another user</Link> first.
          </HelpTooltip>
        </div>
        <button className="theme-button" onClick={this._handleAddButtonClicked}>Add</button>
      </div>
    );
  }

  private _renderLicenseOption = (license: ExternalLicense) => {
    return (
      <option value={license.id} key={license.id}>
        {license.productName}
      </option>
    )
  };
}
