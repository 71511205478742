import React from 'react';
import './styles.scss';

export interface PageErrorProps {
  error: string;
  format?: 'quote'|'paragraph'
  title?: string;
  additionalFooterContent?: JSX.Element;
}

const PageError = (props: PageErrorProps) => (
  <div className="page-error">
    <h2>{props.title || 'Yikes—an error occurred'}</h2>
    {props.format === 'paragraph' ? <p className="message">{props.error}</p> : <blockquote className="message">{props.error}</blockquote>}
    <div className="footer">
      <p>
        If you continue encountering this issue, please <a href="https://vuplex.com/contact">contact us</a> for support.
      </p>
      {props.additionalFooterContent}
    </div>
  </div>
);
export default PageError;
