import React, { Component } from 'react';
import './styles.scss';

interface ModalProps {
  className?: string;
  onCloseRequested?: () => void;
}

export default class Modal extends Component<ModalProps> {

  render() {
    return (
      <div className="modal-backdrop" ref={this._backdrop} onClick={this._handleBackdropClicked}>
        <div className={`modal-content ${this.props.className || ''}`}>
          {this._renderCloseButton()}
          {this.props.children}
        </div>
      </div>
    );
  }

  private _backdrop = React.createRef<HTMLDivElement>();

  private _handleBackdropClicked = (event) => {
    // Only close the modal if the backdrop specifically was clicked
    // (ignore if its child was clicked).
    if (this.props.onCloseRequested && event.target === this._backdrop.current) {
      this.props.onCloseRequested();
    }
  }

  private _handleCloseButtonClicked = () => this.props.onCloseRequested!();

  private _renderCloseButton() {

    if (!this.props.onCloseRequested) {
      return;
    }
    // Use a custom modal-close-button tag so that
    // the user's CSS doesn't accidentally style it.
    return React.createElement(
      'modal-close-button',
      { role: 'button', onClick: this._handleCloseButtonClicked },
      '×'
    );
  }
}
