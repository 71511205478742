
export default function parseQueryString(queryString: string): {[param: string]: string} {

  const hashes = queryString.slice(queryString.indexOf('?') + 1).split('&');
  const params = {};
  for (const hash of hashes) {
    const [ key, val ] = hash.split('=');
    params[key] = decodeURIComponent(val);
  }
  return params;
}
