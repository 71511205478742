import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import UserProductSummary from '../../../web-common/models/accounts/UserProductSummary';
import ReleaseDownloadLink from '../../ReleaseDownloadLink';
import '../../../shared-styles/card.scss';
import './styles.scss';

export default class ProductDownload extends Component<{ productSummary: UserProductSummary }> {

  render() {

    const { product } = this.props.productSummary;

    return (
      <div className="product-download card">
        <div>
          <Link to={`/products/${product.id}`} className="releases-link">
            <h2>{product.name}</h2>
            <div>See all releases</div>
          </Link>
          <div className="download-options">
            {this._renderLatestReleaseLink()}
          </div>
        </div>
      </div>
    );
  }

  private get _latestRelease() {

    return this.props.productSummary.releases[0];
  }

  private _renderLatestReleaseLink() {

    if (this._latestRelease) {
      return <ReleaseDownloadLink release={this._latestRelease}>Download latest</ReleaseDownloadLink>;
    }
  }
}
