import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom'
import { Helmet } from 'react-helmet';
import ExternalUser from '../../web-common/models/accounts/ExternalUser';
import ExternalLicense from '../../web-common/models/accounts/ExternalLicense';
import PublicApiError from '../../web-common/models/errors/PublicApiError';
import Spinner from '../../web-common/components/Spinner';
import addImage from '../../web-common/assets/add.svg';
import LoginRedirectError from '../../web-common/client/LoginRedirectError';
import AccountService from '../../services/AccountService';
import PageError from '../../web-common/components/PageError';
import PageHeader from '../PageHeader';
import PageSpinner from '../PageSpinner';
import AddLicenseModal from './AddLicenseModal';
import License from './License';
import '../../shared-styles/empty-container.scss';
import '../../web-common/styles/link-button.scss';
import './styles.scss';
import VuplexApi from '../../web-common/client/VuplexApi';

export default class UserPage extends Component<RouteComponentProps<{ userId: string }>> {

  state = {
    error: '',
    user: null as ExternalUser | null,
    licenses: null as ExternalLicense[] | null,
    showAddLicenseModal: false,
    updateIsPending: false
  }

  async componentDidMount() {

    const { userId } = this.props.match.params;
    try {
      const [user, licenses] = await Promise.all([
        VuplexApi.getUser(userId),
        AccountService.getLicenses()
      ]);
      this.setState({ user, licenses });
    } catch (error) {
      if (error instanceof LoginRedirectError) {
        // Ignore this error
        return;
      }
      const errorMessage = (error instanceof PublicApiError && error.userMessage) || `An unexpected error occurred while loading account data. ${error}`;
      this.setState({ error: errorMessage });
    }
  }

  render() {

    const { user } = this.state;

    return (
      <div className="user-page">
        <Helmet>
          <title>{user ? `User: ${user.email}` : 'User'} | Vuplex</title>
          <meta name="description" content="Update user information." />
        </Helmet>
        {this._renderContent()}
      </div>
    )
  }

  private _handleAddLicenseButtonClicked = () => {

    const accountHasOtherLicenses = !!this.state.licenses!.filter(l => l.userId !== this.state.user!.id).length;
    if (accountHasOtherLicenses) {
      this.setState({ showAddLicenseModal: true });
    } else {
      this.props.history.push('/overview?addProduct=true');
    }
  };

  private _handleAddLicenseRequested = async (licenseId: string) => {
    this.setState({ showAddLicenseModal: false, updateIsPending: true })
    try {
      await AccountService.updateLicense(licenseId, this.state.user!.id);
      const { licenses } = this.state;
      const license = licenses!.find(l => l.id === licenseId);
      license!.userId = this.state.user!.id;
      this.setState({ updateIsPending: false, licenses });
    } catch (error) {
      const message = `An unexpected error occurred while adding the license: ${error.message}`;
      console.error(message);
      this.setState({ error: message });
    }
  };

  private _handleRemoveLicenseRequested = async (licenseId: string) => {
    try {
      await AccountService.updateLicense(licenseId, 'unassigned');
      const { licenses } = this.state;
      const license = licenses!.find(l => l.id === licenseId);
      license!.userId = 'unassigned';
      this.setState({ licenses });
    } catch (error) {
      const message = `An unexpected error occurred while removing the license: ${error.message}`;
      console.error(message);
      this.setState({ error: message });
    }
  };

  private _handleModalCloseRequested = () => this.setState({ showAddLicenseModal: false });

  private _renderAddLicenseModal() {

    if (this.state.showAddLicenseModal) {
      return (
        <AddLicenseModal
          userId={this.state.user!.id}
          licenses={this.state.licenses!}
          onAddRequested={this._handleAddLicenseRequested}
          onCloseRequested={this._handleModalCloseRequested}/>
      );
    }
  }

  private _renderContent() {

    if (this.state.error) {
      return <PageError error={this.state.error}/>;
    }
    if (!(this.state.user && this.state.licenses)) {
      return <PageSpinner/>;
    }

    return (
      <div>
        <section>
          <PageHeader back="/admin/users" label="User">{this.state.user.email}</PageHeader>
          {this._renderLicensesSection()}
        </section>
        {this._renderAddLicenseModal()}
      </div>
    );
  }

  private _renderLicensesSection() {

    if (this.state.updateIsPending) {
      return <Spinner className="bottom-spinner"/>;
    }

    return (
      <div>
        <h2>Licenses:</h2>
        {this._renderLicenses()}
        <button className="link-button add-button" onClick={this._handleAddLicenseButtonClicked}>
          <img src={addImage} alt="add symbol" />
            Add license
        </button>
      </div>
    );
  }

  private _renderLicenses() {

    const userLicenses = this.state.licenses!.filter(l => l.userId === this.props.match.params.userId);

    if (userLicenses!.length) {
      return (
        <div>
          {userLicenses!.map(l => <License license={l} onRemoveRequested={this._handleRemoveLicenseRequested} key={l.id}/>)}
        </div>
      );
    }
    return (
      <div className="empty-container no-licenses">
        No licenses assigned
      </div>
    );
  }
}
