import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import UserProductRelease from '../../web-common/models/accounts/UserProductRelease';
import Modal from '../../web-common/components/Modal';
import Spinner from '../../web-common/components/Spinner';
import AccountService from '../../services/AccountService';
import downloadImage from './assets/download.svg';
import './styles.scss';

interface ReleaseDownloadLinkProps {
  release: UserProductRelease;
  className?: string;
}

export default class ReleaseDownloadLink extends Component<ReleaseDownloadLinkProps> {

  state = {
    isLoading: false,
    showWarningModal: false
  };

  render() {

    const classNames = ['download-link'];
    if (this.state.isLoading) {
      classNames.push('loading');
    }
    if (this.props.className) {
      classNames.push(this.props.className);
    }
    return (
      <div>
        <a onClick={this._handleClick} className={classNames.join(' ')}>
          {this._renderIcon()}
          <div>
            {this.props.children}
          </div>
        </a>
        {this._renderWarningModal()}
      </div>
    );
  }

  private _handleClick = async () => {

    if (this.state.isLoading) {
      return;
    }

    if (!this.props.release.downloadLinkUrl) {
      // There's no download link, which means this user is an admin
      // who doesn't have a license for the product assigned to them.
      // So, show them a warning.
      this.setState({ showWarningModal: true });
      return;
    }
    try {
      this.setState({ isLoading: true });
      await AccountService.triggerAuthenticatedDownload(this.props.release.downloadLinkUrl);
    } catch (error) {
      console.error('An error occurred while getting the download link: ' + error);
    }
    this.setState({ isLoading: false });
  }

  private _onModalCloseRequested = () => this.setState({ showWarningModal: false });

  private _renderIcon() {

    if (this.state.isLoading) {
      return (
        <Spinner size="25px" className="download-spinner"/>
      );
    }

    return (
      <div className="download">
        <img src={downloadImage} alt="download" />
      </div>
    )
  }

  private _renderWarningModal() {

    if (this.state.showWarningModal) {
      return (
        <Modal
          onCloseRequested={this._onModalCloseRequested}
          className="download-link-modal">
          <div>
            <h2>License required</h2>
            <p>
              Your account has a license for this product, but it is not assigned to your user,
              so you are unable to download it. In order to download it,
              please <Link to="/admin/users">visit the users page</Link> to
              assign the license to your user.
            </p>
          </div>
        </Modal>
      )
    }
  }
}
