import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import Urls from '../../web-common/config/Urls';
import getLoginUrl from '../../web-common/config/getLoginUrl';
import getSignupUrl from '../../web-common/config/getSignupUrl';
import Carousel from '../../web-common/components/Carousel';
import DefaultHead from '../DefaultHead';
import productsImage from './assets/products.jpg';
import releasesImage from './assets/releases.jpg';
import usersImage from './assets/users.jpg';
import './styles.scss';
import SessionStorage from '../../web-common/client/SessionStorage';

interface CarouselItem {
  imageUrl: string;
  imageAlt: string;
  text: string | JSX.Element;
}

const carouselItems: CarouselItem[] = [
  {
    imageUrl: productsImage,
    imageAlt: 'products page',
    text: <span>View and download 3D WebView products purchased from the <a href={Urls.Store}>Vuplex Store</a> and <a href="https://assetstore.unity.com/publishers/40309">Unity Asset Store</a></span>
  },
  {
    imageUrl: releasesImage,
    imageAlt: 'releases page',
    text: 'Access all release notes and downloads for past releases'
  },
  {
    imageUrl: usersImage,
    imageAlt: 'users page',
    text: 'Invite team members and assign licenses to them'
  }
];

export default class HomePage extends Component<RouteComponentProps> {

  componentDidMount() {

    // If the user has a session, then redirect to the overview page.
    const sessionExists = !!SessionStorage.getSession();
    if (sessionExists) {
      this.props.history.replace('/overview');
    }
  }

  render() {
    return (
      <div className="homepage">
        <DefaultHead />
        <div className="top-links">
          <a href={getLoginUrl(location.href)}>
            Log in
          </a>
          <a href={getSignupUrl(location.href)}>
            Sign up
          </a>
        </div>
        <h1>Vuplex developer dashboard</h1>
        <Carousel autoPlayInterval={6}>
          {carouselItems.map(this._carouselItem)}
        </Carousel>
      </div>
    );
  }

  private _carouselItem = (item: CarouselItem) => (
    <div className="carousel-item" key={item.imageAlt}>
      <img src={item.imageUrl} alt={item.imageAlt}/>
      <p>
        {item.text}
      </p>
    </div>
  );
}
