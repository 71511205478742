import React, { Component } from 'react';
import { Switch, Route, withRouter, RouteComponentProps } from 'react-router-dom';
import Navbar from '../../web-common/components/Navbar';
import DefaultHead from '../DefaultHead';
import HomePage from '../HomePage';
import ScrollToTop from '../ScrollToTop';
import OverviewPage from '../OverviewPage';
import InvoicePage from '../InvoicePage';
import InvoicesPage from '../InvoicesPage';
import UserProductPage from '../UserProductPage';
import UserPage from '../UserPage';
import UsersPage from '../UsersPage';
import './styles.scss';

class App extends Component<RouteComponentProps> {

  state = {
    mobileSidebarOpen: false
  }

  render() {

    return (
      <div className="app">
        <ScrollToTop/>
        <DefaultHead/>
        <Navbar
          siteName="dashboard"
          className="dashboard-navbar"
          centerLinks={[
            <a key="store" className="nav-store-link" href="https://store.vuplex.com">store</a>,
            <a key="dev" href="https://developer.vuplex.com">developers</a>,
            <a key="support" href="https://support.vuplex.com">support</a>,
          ]}
        />
        <div className="app-container">
          <div className="page-container">
            <Switch>
              <Route exact path="/" component={HomePage} />
              <Route exact path="/overview" component={OverviewPage} />
              <Route path="/products/:productId" component={UserProductPage} />
              <Route path="/admin/users/:userId" component={UserPage} />
              <Route path="/admin/users" component={UsersPage} />
              <Route path="/admin/invoices/:orderId" component={InvoicePage} />
              <Route path="/admin/invoices" component={InvoicesPage} />
            </Switch>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(App);
