import React from 'react';
import { Helmet } from 'react-helmet';

const DefaultHead = () => (
  <Helmet>
    <title>Dashboard | Vuplex</title>
    <meta name="description" content="Vuplex Dashboard" />
  </Helmet>
);

export default DefaultHead;
