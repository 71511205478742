import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import PublicApiError from '../../web-common/models/errors/PublicApiError';
import AccountService from '../../services/AccountService';
import LoginRedirectError from '../../web-common/client/LoginRedirectError';
import PageError from '../../web-common/components/PageError';
import PageHeader from '../PageHeader';
import PageSpinner from '../PageSpinner';
import Invoice from '../../web-common/models/accounts/Invoice';
import '../../web-common/styles/link-button.scss';
import '../../web-common/styles/button-reset.scss';
import '../../shared-styles/card.scss'
import './styles.scss';
import formatDate from '../../web-common/utils/formatDate';

export default class InvoicesPage extends Component {

  state = {
    error: '',
    invoices: null as Invoice[] | null,
  }

  async componentDidMount() {

    await this._loadInvoices();
  }

  render() {

    return (
      <div className="invoices-page">
        <Helmet>
          <title>Invoices | Vuplex</title>
          <meta name="description" content="View and download your organization's invoices." />
        </Helmet>
        {this._renderContent()}
      </div>
    )
  }

  private _loadInvoices = async () => {

    this.setState({ invoices: null });
    try {
      const invoices = await AccountService.getInvoices();
      this.setState({ invoices });
    } catch (error) {
      if (error instanceof LoginRedirectError) {
        // Ignore this error
        return;
      }
      const errorMessage = (error instanceof PublicApiError && error.userMessage) || `An unexpected error occurred while loading account data. ${error}`;
      this.setState({ error: errorMessage });
    }
  }

  private _renderContent() {

    if (this.state.error) {
      return <PageError error={this.state.error} />;
    }
    if (!this.state.invoices) {
      return <PageSpinner />;
    }
    return (
      <div>
        <section>
          <PageHeader back="/overview">Invoices</PageHeader>
          {this.state.invoices.map(this._renderInvoice)}
        </section>
      </div>
    )
  }

  private _renderInvoice = (invoice: Invoice) => (

    <Link to={`/admin/invoices/${invoice.orderId}`} className="invoice card" key={invoice.orderId}>
      <div>
        <h2>{invoice.name}</h2>
        <div className="date">{formatDate(invoice.orderDateTime)}</div>
      </div>
    </Link>
  )
}
