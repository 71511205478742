import React, { Component } from 'react';
import ExternalLicense from '../../../web-common/models/accounts/ExternalLicense';
import Spinner from '../../../web-common/components/Spinner';
import minusImage from '../../../shared-assets/minus.svg';
import '../../../web-common/styles/button-reset.scss';
import '../../../shared-styles/card-without-hover.scss';
import './styles.scss';

interface LicenseProps {
  license: ExternalLicense;
  onRemoveRequested: (licenseId: string) => void;
}

export default class License extends Component<LicenseProps> {

  state = {
    loading: false
  }

  render() {
    return (
      <div className="card-without-hover license">
        <div>
          <div className="license-top">
            <h2>{this.props.license.productName}</h2>
            {/*
              To make it so that the button and spinner take up the same width (for mobile),
              the spinner is rendered inside of the button, which is effectively hidden while loading.
            */}
            <button
              onClick={this._handleRemoveButtonClicked}
              disabled={this.state.loading}
              className={`button-reset ${this.state.loading ? 'loading' : ''}`}>
              <img src={minusImage} alt="minus symbol" />
              <span>Remove</span>
              {this._renderSpinner()}
            </button>
          </div>
        </div>
      </div>
    );
  }

  private _handleRemoveButtonClicked = () => {

    this.setState({ loading: true });
    this.props.onRemoveRequested(this.props.license.id);
  };

  private _renderSpinner() {

    if (this.state.loading) {
      return (
        <div className="spinner-container">
          <Spinner color="#ff3300" size="25px" />
        </div>
      );
    }
  }
}
