import React, { Component } from 'react';
import ReactMarkdown from 'react-markdown';
import UserProductRelease from '../../../web-common/models/accounts/UserProductRelease';
import formatDate from '../../../web-common/utils/formatDate';
import '../../../web-common/styles/button-reset.scss';
import ReleaseDownloadLink from '../../ReleaseDownloadLink';
import '../../../shared-styles/card-without-hover.scss';
import caretImage from './assets/caret.svg';
import './styles.scss';

export default class ProductRelease extends Component<{ release: UserProductRelease }> {

  state = {
    showReleaseNotes: false
  }

  render() {

    const { release } = this.props;

    return (
      <div className="product-release card-without-hover">
        <div>
          <div>
            <h2>
              {release.version}
              <time dateTime={release.dateTime}>
                {formatDate(release.dateTime)}
              </time>
            </h2>
            <ReleaseDownloadLink className="release-download-link" release={release}>Download</ReleaseDownloadLink>
          </div>
          {this._renderReleaseNotes()}
        </div>
      </div>
    )
  }

  private _handleReleaseNotesButtonClick = () => this.setState({ showReleaseNotes: !this.state.showReleaseNotes });

  private _renderReleaseNotes() {

    const { releaseNotesMarkdown } = this.props.release;
    if (!releaseNotesMarkdown) {
      return;
    }
    return (
      <div>
        <button onClick={this._handleReleaseNotesButtonClick} className="button-reset">
          <img
            src={caretImage}
            className={this.state.showReleaseNotes ? 'flip' : undefined}
            alt={`arrow pointing ${this.state.showReleaseNotes ? 'up' : 'down'}`}
          />
          <span>Release notes</span>
        </button>
        {this.state.showReleaseNotes && <ReactMarkdown className="release-notes" source={releaseNotesMarkdown}/>}
      </div>
    );

  }
}
