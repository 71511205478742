import React, { Component } from 'react';
import Spinner from '../Spinner';
import '../../styles/theme-button.scss';
import './styles.scss';

interface TextSubmissionInputProps {
  value: string;
  onChange: (string) => void;
  onSubmit: () => void;
  submissionInProgress: boolean;
  isValid: boolean;
  buttonText: string;
  placeholder?: string;
  error?: string;
  inputType?: string;
  className?: string;
}

export default class TextSubmissionInput extends Component<TextSubmissionInputProps> {

  render() {
    return (
      <div className={`text-submission-input ${this.props.className || ''}`}>
        <div>
          <input
            value={this.props.value}
            onChange={this._handleChange}
            onKeyDown={this._handleKeyDown}
            placeholder={this.props.placeholder}
            disabled={this.props.submissionInProgress}
            type={this.props.inputType}/>
          <button
            className={`theme-button ${this.props.submissionInProgress ? 'loading' : ''}`}
            disabled={this.props.submissionInProgress || !this.props.isValid}
            onClick={this.props.onSubmit}>
            {this.props.submissionInProgress ? <Spinner size="20px" color="white" /> : this.props.buttonText}
          </button>
        </div>
        {this.props.error && <div className="error">{this.props.error}</div>}
      </div>
    );
  }

  private _handleChange = (event) => this.props.onChange(event.target.value);

  private _handleKeyDown = (event) => {
    // Submit the search when Enter is pressed.
    if (event.keyCode === 13 && this.props.isValid) {
      this.props.onSubmit();
    }
  }
}
